<template>
  <div>
    <dashboard-modal-chart-settings
      v-model="isOpenSettingsTasks"
      :settings="settingsChart"
      :mode="mode"
      @change-settings="changeSettings"
    />

    <dashboard-modal-filters
      v-if="media.isMobile"
      v-model="isOpenFiltersTasks"
      :value-filters="dashboardFiltersValueTasks"
      :mode="mode"
      @input-filters="inputFiltersMobile"
    />

    <dashboard-filters v-else v-model="dashboardFiltersValueTasks" :mode="mode" />

    <dashboard-modal-period
      v-if="media.isMobile"
      v-model="isOpenPeriodTasks"
      :period-filter="dashboardFiltersValueTasks.datePeriod"
      @input-period="inputPeriod"
    />

    <dashboard-summary :loading="loading" :cards="cardSummary" :mode="mode" />

    <dashboard-graph
      :settings="settingsChart"
      :dataset="currentChartData"
      :graphic-type="graphicType"
      :period-to="filterPeriodTo"
      :message-text="messageTextChart"
      @change-settings="changeSettings"
    />
  </div>
</template>

<script>
import DashboardModalChartSettings from '@/components/Dashboard/DashboardModalChartSettings.vue';
import DashboardModalFilters from '@/components/Dashboard/DashboardModalFilter.vue';
import DashboardFilters from '@/components/Dashboard/DashboardFilters.vue';
import DashboardModalPeriod from '@/components/Dashboard/DashboardModalPeriod.vue';
import DashboardSummary from '@/components/Dashboard/DashboardSummary.vue';
import DashboardGraph from '@/components/Dashboard/GraphTasks.vue';

import { getSettingsTasksChart } from '@/components/Dashboard/graph.config';

import { isEqual, isEmptyObject } from '@/utils/common';
import { flushPromises } from '@/utils/scheduler';
import { checkEmptyParams, extractParamsFromMultiSelectObject } from '@/utils/many';
import client from '@/http/client';
import dashboardService from '@/services/dashboard';

import { PAYMENTS, TASKS } from '@/constants/dashboardModes';

const createDefaultCardSummary = () => ({
  pendingTasks: null,
  inProgressTasks: null,
  canceledTasks: null,
  completedTasks: null,
});

const createDefaultPeriod = () => ({
  pendingTasks: [],
  inProgressTasks: [],
  canceledTasks: [],
  completedTasks: [],
});

export default {
  name: 'DashboardTasks',
  components: {
    DashboardModalChartSettings,
    DashboardModalFilters,
    DashboardFilters,
    DashboardModalPeriod,
    DashboardSummary,
    DashboardGraph,
  },
  props: {
    mode: { type: String, default: TASKS },
    isOpenSettings: { type: Boolean, default: false },
    isOpenFilters: { type: Boolean, default: false },
    isOpenPeriod: { type: Boolean, default: false },
    dashboardFiltersValue: { type: Object, required: true },
  },
  data() {
    return {
      settingsChart: getSettingsTasksChart(),
      currentChartData: {
        pendingTasks: [],
        inProgressTasks: [],
        canceledTasks: [],
        completedTasks: [],
      },
      loading: false,
      cardSummary: {},
      graphicType: '',
      filterPeriodTo: '',
      messageTextChart: '',
    };
  },
  inject: ['media'],
  computed: {
    isOpenSettingsTasks: {
      get() {
        return this.isOpenSettings;
      },
      set(newVal) {
        if (this.isOpenSettingsTasks) this.$emit('close-settings');
        return newVal;
      },
    },
    isOpenPeriodTasks: {
      get() {
        return this.isOpenPeriod;
      },
      set(newVal) {
        if (this.isOpenPeriodTasks) this.$emit('close-period');
        return newVal;
      },
    },
    isOpenFiltersTasks: {
      get() {
        return this.isOpenFilters;
      },
      set(newVal) {
        if (this.isOpenFiltersTasks) this.$emit('close-filters');
        return newVal;
      },
    },
    dashboardFiltersValueTasks: {
      get() {
        return this.dashboardFiltersValue;
      },
      set(newVal) {
        if (this.dashboardFiltersValue) this.$emit('set-filters', newVal);
        return newVal;
      },
    },
    isPaymentsMode() {
      return this.mode === PAYMENTS;
    },
    isTasksMode() {
      return this.mode === TASKS;
    },
  },
  watch: {
    dashboardFiltersValueTasks: {
      async handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) await this.initDashboard(newVal);
      },
      deep: true,
    },
  },
  mounted() {
    this.initDashboard(this.dashboardFiltersValueTasks).then(res => {
      if (isEmptyObject(res)) {
        this.$emit('missing-data');
      }
    });
  },
  methods: {
    async initDashboard({ datePeriod, servicesTypes, projects, clients, performers }) {
      if (this.$options.cancelSource) {
        this.$options.cancelSource.cancel();
        await flushPromises();
      }

      this.loading = true;

      try {
        const cancelSource = client.getCancelToken();
        this.$options.cancelSource = cancelSource;

        const requestBody = this.normalizeFilters({ servicesTypes, projects, clients, performers });

        const { summary, forAllTime, graphicType, dateTo } = await dashboardService.getCompanyGraphicDataTasks(
          {
            taskDateFrom: datePeriod[0],
            taskDateTo: datePeriod[1],
            ...requestBody,
          },
          { cancelToken: cancelSource.token }
        );

        this.setInfo({ summary, forAllTime, graphicType, dateTo });

        return { summary, forAllTime, graphicType, dateTo };
      } finally {
        this.loading = false;
        this.$options.cancelSource = null;
      }
    },
    setInfo({ summary, forAllTime, graphicType, dateTo }) {
      this.messageTextChart = '';

      this.cardSummary = summary || createDefaultCardSummary();
      this.graphicType = graphicType || '';
      this.filterPeriodTo = dateTo || '';

      if (forAllTime) {
        this.currentChartData = forAllTime;
      } else {
        this.messageTextChart = 'label.not_data_filter';
        this.currentChartData = createDefaultPeriod();
      }
    },
    normalizeFilters({ servicesTypes, projects, clients, performers }) {
      return {
        serviceTypes: checkEmptyParams(servicesTypes) ? undefined : extractParamsFromMultiSelectObject(servicesTypes),
        projects: checkEmptyParams(projects) ? undefined : extractParamsFromMultiSelectObject(projects),
        clients: checkEmptyParams(clients) ? undefined : extractParamsFromMultiSelectObject(clients),
        performers: checkEmptyParams(performers) ? undefined : extractParamsFromMultiSelectObject(performers),
      };
    },
    inputPeriod(dates) {
      this.dashboardFiltersValueTasks = { ...this.dashboardFiltersValueTasks, datePeriod: dates };
    },
    inputFiltersMobile(filters) {
      this.dashboardFiltersValueTasks = filters;
    },
    changeSettings(settings) {
      this.settingsChart = settings;
    },
  },
  cancelSource: null,
};
</script>
